import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      disableAll: true,
      disableSpecific: true,
      selectedBusiness: "",
      businessData: [],
      selectedUser: "",
      userData: [],
      selectedGroupBy: "",
      groupByData: [
        { text: "Date", value: "D" },
        { text: "User", value: "U" },
      ],
      dateSelected: false,
      runDisabled: true,
      specificDialog: false,
      partClassSearchValid: false,
      page: 1,
      totalPages: 0,
      showPartNum: false,
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      partResultList: [],
      showPartDetails: false,
      showBusiness: false,
      showTransactionType: false,
      showUsers: false,
      showGroupBy: false,
      reportData: [],
      excelName: "",
      totalRecords: 0,
      exportDisable: true,
      export50kData: false,
      exportDialog: false,
      showData: false,
      showPartType: false,
      showStartDate: true,
      showEndDate: true,
      debouncedGetPartList: [],
      passFail: false,
      apiObj: {
        pass: 0,
        userid: 0,
        lcsdate: "",
        lcedate: "",
        proj_key: 0,
        part_num: "",
        loc: "",
        bu_key: 0,
        xuser_key: 0,
        sortby: "",
        PageNumber: 0,
        export: 0,
        guid: "",
      },
      sortBy: "Date",
      sortDesc: false,
      headerUser: [
        { text: "User", value: "User", class: "primary customwhite--text" },
        { text: "<06AM", value: "<06AM", class: "primary customwhite--text" },
        { text: "<07AM", value: "<07AM", class: "primary customwhite--text" },
        { text: "<08AM", value: "<08AM", class: "primary customwhite--text" },
        { text: "<09AM", value: "<09AM", class: "primary customwhite--text" },
        { text: "<10AM", value: "<10AM", class: "primary customwhite--text" },
        { text: "<11AM", value: "<11AM", class: "primary customwhite--text" },
        { text: "<Noon", value: "Noon", class: "primary customwhite--text" },
        { text: "<01PM", value: "<01PM", class: "primary customwhite--text" },
        { text: "<02PM", value: "<02PM", class: "primary customwhite--text" },
        { text: "<03PM", value: "<03PM", class: "primary customwhite--text" },
        { text: "<04PM", value: "<04PM", class: "primary customwhite--text" },
        { text: "<05PM", value: "<05PM", class: "primary customwhite--text" },
        { text: "<06PM", value: "<06PM", class: "primary customwhite--text" },
        { text: "<07PM", value: "<07PM", class: "primary customwhite--text" },
        { text: "<08PM", value: "<08PM", class: "primary customwhite--text" },
        { text: "<09PM", value: "<09PM", class: "primary customwhite--text" },
        { text: "<10PM", value: "<10PM", class: "primary customwhite--text" },
        { text: "<11PM", value: "<11PM", class: "primary customwhite--text" },
        { text: "<Midnight", value: "Midnight", class: "primary customwhite--text" },
        { text: "Total", value: "Total", class: "primary customwhite--text" },
      ],
      headerDate: [
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "<06AM", value: "<06AM", class: "primary customwhite--text" },
        { text: "<07AM", value: "<07AM", class: "primary customwhite--text" },
        { text: "<08AM", value: "<08AM", class: "primary customwhite--text" },
        { text: "<09AM", value: "<09AM", class: "primary customwhite--text" },
        { text: "<10AM", value: "<10AM", class: "primary customwhite--text" },
        { text: "<11AM", value: "<11AM", class: "primary customwhite--text" },
        { text: "<Noon", value: "Noon", class: "primary customwhite--text" },
        { text: "<01PM", value: "<01PM", class: "primary customwhite--text" },
        { text: "<02PM", value: "<02PM", class: "primary customwhite--text" },
        { text: "<03PM", value: "<03PM", class: "primary customwhite--text" },
        { text: "<04PM", value: "<04PM", class: "primary customwhite--text" },
        { text: "<05PM", value: "<05PM", class: "primary customwhite--text" },
        { text: "<06PM", value: "<06PM", class: "primary customwhite--text" },
        { text: "<07PM", value: "<07PM", class: "primary customwhite--text" },
        { text: "<08PM", value: "<08PM", class: "primary customwhite--text" },
        { text: "<09PM", value: "<09PM", class: "primary customwhite--text" },
        { text: "<10PM", value: "<10PM", class: "primary customwhite--text" },
        { text: "<11PM", value: "<11PM", class: "primary customwhite--text" },
        { text: "<Midnight", value: "Midnight", class: "primary customwhite--text" },
        { text: "Total", value: "Total", class: "primary customwhite--text" },
      ],
      headerPFUser: [
        { text: "User", value: "User", class: "primary customwhite--text" },
        { text: "<06AM", value: "<06AM", class: "primary customwhite--text" },
        { text: "<07AM", value: "<07AM", class: "primary customwhite--text" },
        { text: "<08AM", value: "<08AM", class: "primary customwhite--text" },
        { text: "<09AM", value: "<09AM", class: "primary customwhite--text" },
        { text: "<10AM", value: "<10AM", class: "primary customwhite--text" },
        { text: "<11AM", value: "<11AM", class: "primary customwhite--text" },
        { text: "<Noon", value: "Noon", class: "primary customwhite--text" },
        { text: "<01PM", value: "<01PM", class: "primary customwhite--text" },
        { text: "<02PM", value: "<02PM", class: "primary customwhite--text" },
        { text: "<03PM", value: "<03PM", class: "primary customwhite--text" },
        { text: "<04PM", value: "<04PM", class: "primary customwhite--text" },
        { text: "<05PM", value: "<05PM", class: "primary customwhite--text" },
        { text: "<06PM", value: "<06PM", class: "primary customwhite--text" },
        { text: "<07PM", value: "<07PM", class: "primary customwhite--text" },
        { text: "<08PM", value: "<08PM", class: "primary customwhite--text" },
        { text: "<09PM", value: "<09PM", class: "primary customwhite--text" },
        { text: "<10PM", value: "<10PM", class: "primary customwhite--text" },
        { text: "<11PM", value: "<11PM", class: "primary customwhite--text" },
        { text: "<Midnight", value: "Midnight", class: "primary customwhite--text" },
        { text: "Pass", value: "Pass", class: "primary customwhite--text" },
        { text: "Fail", value: "Fail", class: "primary customwhite--text" },
        { text: "Total", value: "Total", class: "primary customwhite--text" },
      ],
      headerPFDate: [
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "<06AM", value: "<06AM", class: "primary customwhite--text" },
        { text: "<07AM", value: "<07AM", class: "primary customwhite--text" },
        { text: "<08AM", value: "<08AM", class: "primary customwhite--text" },
        { text: "<09AM", value: "<09AM", class: "primary customwhite--text" },
        { text: "<10AM", value: "<10AM", class: "primary customwhite--text" },
        { text: "<11AM", value: "<11AM", class: "primary customwhite--text" },
        { text: "<Noon", value: "Noon", class: "primary customwhite--text" },
        { text: "<01PM", value: "<01PM", class: "primary customwhite--text" },
        { text: "<02PM", value: "<02PM", class: "primary customwhite--text" },
        { text: "<03PM", value: "<03PM", class: "primary customwhite--text" },
        { text: "<04PM", value: "<04PM", class: "primary customwhite--text" },
        { text: "<05PM", value: "<05PM", class: "primary customwhite--text" },
        { text: "<06PM", value: "<06PM", class: "primary customwhite--text" },
        { text: "<07PM", value: "<07PM", class: "primary customwhite--text" },
        { text: "<08PM", value: "<08PM", class: "primary customwhite--text" },
        { text: "<09PM", value: "<09PM", class: "primary customwhite--text" },
        { text: "<10PM", value: "<10PM", class: "primary customwhite--text" },
        { text: "<11PM", value: "<11PM", class: "primary customwhite--text" },
        { text: "<Midnight", value: "Midnight", class: "primary customwhite--text" },
        { text: "Pass", value: "Pass", class: "primary customwhite--text" },
        { text: "Fail", value: "Fail", class: "primary customwhite--text" },
        { text: "Total", value: "Total", class: "primary customwhite--text" },
      ],
      json_fieldsDate: {
        Date: "Date",
        "<06AM": "<06AM",
        "<07AM": "<07AM",
        "<08AM": "<08AM",
        "<09AM": "<09AM",
        "<10AM": "<10AM",
        "<11AM": "<11AM",
        Noon: "Noon",
        "<01PM": "<01PM",
        "<02PM": "<02PM",
        "<03PM": "<03PM",
        "<04PM": "<04PM",
        "<05PM": "<05PM",
        "<06PM": "<06PM",
        "<07PM": "<07PM",
        "<08PM": "<08PM",
        "<09PM": "<09PM",
        "<10PM": "<10PM",
        "<11PM": "<11PM",
        Midnight: "Midnight",
        Total: "Total",
      },
      json_fieldsUser: {
        User: "User",
        "<06AM": "<06AM",
        "<07AM": "<07AM",
        "<08AM": "<08AM",
        "<09AM": "<09AM",
        "<10AM": "<10AM",
        "<11AM": "<11AM",
        Noon: "Noon",
        "<01PM": "<01PM",
        "<02PM": "<02PM",
        "<03PM": "<03PM",
        "<04PM": "<04PM",
        "<05PM": "<05PM",
        "<06PM": "<06PM",
        "<07PM": "<07PM",
        "<08PM": "<08PM",
        "<09PM": "<09PM",
        "<10PM": "<10PM",
        "<11PM": "<11PM",
        Midnight: "Midnight",
        Total: "Total",
      },
      json_fieldsPFDate: {
        Date: "Date",
        "<06AM": "<06AM",
        "<07AM": "<07AM",
        "<08AM": "<08AM",
        "<09AM": "<09AM",
        "<10AM": "<10AM",
        "<11AM": "<11AM",
        Noon: "Noon",
        "<01PM": "<01PM",
        "<02PM": "<02PM",
        "<03PM": "<03PM",
        "<04PM": "<04PM",
        "<05PM": "<05PM",
        "<06PM": "<06PM",
        "<07PM": "<07PM",
        "<08PM": "<08PM",
        "<09PM": "<09PM",
        "<10PM": "<10PM",
        "<11PM": "<11PM",
        Midnight: "Midnight",
        Pass: "Pass",
        Fail: "Fail",
        Total: "Total",
      },
      json_fieldsPFUser: {
        User: "User",
        "<06AM": "<06AM",
        "<07AM": "<07AM",
        "<08AM": "<08AM",
        "<09AM": "<09AM",
        "<10AM": "<10AM",
        "<11AM": "<11AM",
        Noon: "Noon",
        "<01PM": "<01PM",
        "<02PM": "<02PM",
        "<03PM": "<03PM",
        "<04PM": "<04PM",
        "<05PM": "<05PM",
        "<06PM": "<06PM",
        "<07PM": "<07PM",
        "<08PM": "<08PM",
        "<09PM": "<09PM",
        "<10PM": "<10PM",
        "<11PM": "<11PM",
        Midnight: "Midnight",
        Pass: "Pass",
        Fail: "Fail",
        Total: "Total",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.disableAll = true;
      this.disableSpecific = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedUser = "";
      this.userData = [];
      this.selectedGroupBy = "";
      this.dateSelected = false;
      this.runDisabled = true;
      this.specificDialog = false;
      this.partClassSearchValid = false;
      this.page = 1;
      this.totalPages = 0;
      this.showPartNum = false;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.showTransactionType = false;
      this.showBusiness = false;
      this.showUsers = false;
      this.showGroupBy = false;
      this.reportData = [];
      this.excelName = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.showData = false;
      this.showPartType = false;
      this.passFail = false;
      this.apiObj = {
        pass: 0,
        userid: 0,
        lcsdate: "",
        lcedate: "",
        proj_key: 0,
        part_num: "",
        loc: "",
        bu_key: 0,
        xuser_key: 0,
        sortby: "",
        PageNumber: 0,
        export: 0,
        guid: "",
      };
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 0;
      this.apiObj.userid = parseInt(this.userId);
      let showMessage = false;
      let projData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    onChangeProject() {
      this.selectedTransactionType = "";
      this.disableAll = false;
      this.disableSpecific = false;
      this.runDisabled = true;
      this.showPartNum = true;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.showTransactionType = false;
      this.showBusiness = false;
      this.showUsers = false;
      this.showGroupBy = false;
      this.reportData = [];
      this.totalRecords = 0;
      this.totalPages = 0;
      this.exportDisable = true;
      this.showData = false;
      this.showPartType = false;
      this.clearPartShip();
    },
    //All Part Clicked
    async allPartNumber() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 1;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      let showMessage = false;
      let tranData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (tranData.message !== "NA") {
        this.transactionTypeData = [];
        this.transactionTypeData = tranData;
        this.showTransactionType = true;
        this.showBusiness = false;
        this.showPartType = true;
        this.showParentClass = false;
        this.showPartClass = false;
        this.showUsers = false;
        this.showInventoryLoc = false;
        this.runDisabled = true;
        this.hideSpecific = true;
        this.showData = false;
        this.clearPartShip();
      }
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    //Part Ship Clear button
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    //Part Description once clicked
    async showPartDesc(item) {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 1;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.part_num = item.PartNo;
      let showMessage = false;
      let tranData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (tranData.message !== "NA") {
        this.transactionTypeData = [];
        this.transactionTypeData = tranData;
        this.showPartDetails = true;
        this.partNum = item.PartNo;
        this.partDesc = item.Description;
        this.partSearchKey = item.PartNo;
        this.showPartNumDetails = false;
        this.partDetailsShow = false;
        this.specificDialog = false;
        this.showBusiness = false;
        this.showTransactionType = true;
        this.showPartType = true;
        this.showParentClass = false;
        this.showPartClass = false;
        this.showUsers = false;
        this.showInventoryLoc = false;
        this.runDisabled = true;
        this.hideAll = true;
        this.showData = false;
      }
    },
    //Transaction Type selected
    async transactionTypeSelected() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 2;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.loc = this.selectedTransactionType;
      let showMessage = false;
      let busData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.runDisabled = true;
        this.showBusiness = true;
        this.showUsers = false;
        this.showGroupBy = false;
        this.reportData = [];
        this.totalRecords = 0;
        this.totalPages = 0;
        this.exportDisable = true;
        this.showData = false;
        this.selectedBusiness = "";
      }
    },
    //Business selected
    async businessSelected() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 3;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.loc = this.selectedTransactionType;
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      let showMessage = false;
      let otherData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (otherData.message !== "NA") {
        this.userData = otherData;
        this.selectedUser = "";
        this.selectedGroupBy = "";
        this.runDisabled = true;
        this.showUsers = true;
        this.showGroupBy = false;
        this.showData = false;
      }
    },
    //Warehouse selected
    userSelected() {
      this.selectedGroupBy = "";
      this.showGroupBy = true;
      this.runDisabled = true;
      this.showData = false;
    },
    //Class selected
    groupBySelected() {
      this.runDisabled = false;
      this.showData = false;
    },
    //Run Report
    async runReport(page) {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 4;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.loc = this.selectedTransactionType;
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      this.apiObj.xuser_key = parseInt(this.selectedUser);
      this.apiObj.sortby = this.selectedGroupBy;
      this.apiObj.PageNumber = page;
      this.apiObj.export = 0;
      this.apiObj.part_num = this.partNum;
      let showMessage = false;
      let reportData = await reportService.productivityAnalysisReport("post", this.apiObj, showMessage);
      if (reportData.message !== "NA") {
        if (reportData.Resultset.length > 0) {
          this.reportData = reportData.Resultset;
          this.passFail = false;
          this.passFail = this.reportData.find((user) => "Pass" in user || "Fail" in user) !== undefined;
          this.totalRecords = reportData.TotalRecords;
          this.exportDisable = false;
          this.totalPages = reportData.TotalPages;
          this.showData = true;
        } else {
          this.reportData = [];
          this.totalRecords = 0;
          this.totalPages = 0;
          this.exportDisable = true;
          this.showData = false;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //JSON field change
    jsonFields() {
      if (this.passFail) {
        if (this.selectedGroupBy == "D") {
          return this.json_fieldsPFDate;
        } else {
          return this.json_fieldsPFUser;
        }
      } else {
        if (this.selectedGroupBy == "D") {
          return this.json_fieldsDate;
        } else {
          return this.json_fieldsUser;
        }
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
        this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
        this.apiObj.pass = 4;
        this.apiObj.userid = parseInt(this.userId);
        this.apiObj.proj_key = parseInt(this.selectedProject);
        this.apiObj.loc = this.selectedTransactionType;
        this.apiObj.bu_key = parseInt(this.selectedBusiness);
        this.apiObj.xuser_key = parseInt(this.selectedUser);
        this.apiObj.sortby = this.selectedGroupBy;
        this.apiObj.PageNumber = 1;
        this.apiObj.export = 1;
        this.apiObj.part_num = this.partNum;
        const response = await this.axios.post("/rt/productivity_analysis_report", this.apiObj);
        let responseData = JSON.parse(response.data.body.message).Resultset;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Process Throughput Summary");
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Style configuration
        const colors = {
          grey: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } },
          lightGrey: { type: "pattern", pattern: "solid", fgColor: { argb: "DBDDE9" } },
        };
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        let projName = this.projectList.filter((obj) => obj.proj_key == this.selectedProject);
        let transType = this.transactionTypeData.filter((obj) => obj.loc == this.selectedTransactionType);
        let business = this.businessData.filter((obj) => obj.bu_key == this.selectedBusiness);
        let user = this.userData.filter((obj) => obj.xuser_key == this.selectedUser);
        let group = this.selectedGroupBy == "D" ? "Date" : "User";
        let endDate = this.eDate ? this.eDate : this.maxEndDate;
        worksheet.addRow(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow(["PRODUCTIVITY ANALYSIS", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow([
          "Date Range: " + this.sDate + " To " + endDate,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
        worksheet.addRow(["Project: " + projName[0].project, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow([
          "Transaction Type: " + transType[0].wc_name,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
        worksheet.addRow(["Business: " + business[0].business, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow(["User: " + user[0].username, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow(["Group By: " + group, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        worksheet.addRow([
          group,
          "<06AM",
          "<07AM",
          "<08AM",
          "<09AM",
          "<10AM",
          "<11AM",
          "<Noon",
          "<01PM",
          "<02PM",
          "<03PM",
          "<04PM",
          "<05PM",
          "<06PM",
          "<07PM",
          "<08PM",
          "<09PM",
          "<10PM",
          "<11PM",
          "<Midnight",
          "Total",
        ]);
        worksheet.mergeCells("A2:U2");
        worksheet.mergeCells("A3:U3");
        worksheet.mergeCells("A4:U4");
        worksheet.mergeCells("A5:U5");
        worksheet.mergeCells("A6:U6");
        worksheet.mergeCells("A7:U7");
        worksheet.mergeCells("A8:U8");
        worksheet.mergeCells("A9:U9");
        worksheet.getRow(2).eachCell((cell) => {
          cell.style = { alignment: { horizontal: "center", vertical: "middle" } };
          cell.border = borderStyle;
        });
        for (let i = 3; i < 9; i++) {
          worksheet.getRow(i).eachCell((cell) => {
            cell.border = borderStyle;
          });
        }
        // Apply styles to the header rows
        worksheet.getRow(10).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        // Add your data rows
        responseData.forEach((item) => {
          const row = worksheet.addRow([
            item[group],
            item["<06AM"],
            item["<07AM"],
            item["<08AM"],
            item["<09AM"],
            item["<10AM"],
            item["<11AM"],
            item["Noon"],
            item["<01PM"],
            item["<02PM"],
            item["<03PM"],
            item["<04PM"],
            item["<05PM"],
            item["<06PM"],
            item["<07PM"],
            item["<08PM"],
            item["<09PM"],
            item["<10PM"],
            item["<11PM"],
            item["Midnight"],
            item["Total"],
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = borderStyle;
          });
        });
        this.export50kData = false;
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Productivity_Analysis_Report"));
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //End the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
